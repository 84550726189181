import { createSlice } from "@reduxjs/toolkit";
import partners, { coBrandList, partnersList } from "../../config/partners";

const partner_name = process.env.REACT_APP_PARTNER_API_NAME;
const partner = partnersList.includes(partner_name) ? partner_name : "l";

// Get the utm_source parameter from the URL
let coBrandLogoURL = null;
if (partner_name === "l") {
  const urlParams = new URLSearchParams(window.location.search);
  let coBrand = urlParams.get('utm_source');
  if (coBrand) {
    if (coBrandList[coBrand]) {
      document.cookie = `coBrand=${coBrand}`;
    }else{
      document.cookie = `coBrand=`;
    }
  }else{
    const coBrandCookie = document.cookie
        .split("; ")
        .find((pair) => pair.startsWith("coBrand="));
    coBrand = coBrandCookie ? coBrandCookie.substring(8) : null;
  }
  if (coBrand && coBrandList[coBrand]) {
    coBrandLogoURL = coBrandList[coBrand].logo;
  }
}

const initialState = {
  partner: partner,
  logo: partners[partner].logo,
  googleFontsAPI: partners[partner].googleFontsAPI,
  fontFamily: partners[partner].fontFamily,
  coBrandLogoURL: coBrandLogoURL || null,
};

export const appInfoSlice = createSlice({
  name: "appInfo",
  initialState,
  reducers: {
    updateLogoLink: (state, action) => {
      state.partner = action.payload.partner;
      state.logo = action.payload.logo;
      state.googleFontsAPI = action.payload.googleFontsAPI;
      state.fontFamily = action.payload.fontFamily;
      state.coBrandLogoURL = action.payload.coBrandLogoURL;
    },
  },
});

// actions export
export const { updateLogoLink } = appInfoSlice.actions;

// initial state export
export { initialState };

export default appInfoSlice.reducer;
