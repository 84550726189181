import { s3StaticImagePath } from ".";

export const partnersList = [
  "vakrangee",
  "fastpay",
  "comparewise",
  "gsunite",
  "moneychamp",
  "iwebtechno",
  "toshanipremium",
  "faydapay",
  "vakrangeegold",
  "askcredit",
  "lidservices",
  "paymonk",
  "jeevan-utthan",
  "inforts",
  "1touchpe",
  "ezeepay",
  "dirsti",
  "visitmyloan",
  "winoraa",
  "ygocompany",
  "nitya_finance",
  "daypfinance",
  "aadarsh_finance",
  "realzone",
  "moneyflow",
  "webpe",
  "viren",
  "moral-e-suvidha",
  "yoke",
  "indezon",
  "dhanvriddhiassurance",
  "fivekcr",
  "saralpe"
];

export const coBrandList = {
  carinfo: {
    logo: "https://creditlinks-lap.s3.ap-south-1.amazonaws.com/static/1740386309-carinfo.png",
  },
};

export const partners = {
  // "l" is not partners. it's define for default value of below property on main site version
  l: {
    logo: `${s3StaticImagePath}/1669802513-CreditLinks_blue_logo.webp`,
    googleFontsAPI:
      "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap",
    fontFamily: "'Roboto', sans-serif",
    colors: {
      primary: "#4EC9DB",
      secondary: "#070050",
    },
    tancApply: false,
  },
  saralpe: {
    logo: `${s3StaticImagePath}/1669802513-CreditLinks_blue_logo.webp`,
    googleFontsAPI:
      "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap",
    fontFamily: "'Roboto', sans-serif",
    colors: {
      primary: "#4EC9DB",
      secondary: "#070050",
    },
    tancApply: false,
  },
  comparewise: {
    logo: "/img/partners/comparewise.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Poppins', sans-serif",
    colors: {
      primary: "#68CEA8",
      secondary: "#183d47",
    },
    tancApply: false,
  },
  vakrangee: {
    logo: "/img/partners/vakrangee.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap",
    fontFamily: "'Lato', sans-serif",
    colors: {
      primary: "#ED7725",
      secondary: "#000000",
    },
    tancApply: false,
  },
  fastpay: {
    logo: "/img/partners/edha.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap",
    fontFamily: "'Work Sans', sans-serif",
    colors: {
      primary: "#B32117",
      secondary: "#666666",
    },
    tancApply: false,
  },
  gsunite: {
    logo: "/img/partners/gsunite.jpeg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap",
    fontFamily: "'Open Sans', sans-serif",
    colors: {
      primary: "#047352",
      secondary: "#353692",
    },
    tancApply: false,
  },
  moneychamp: {
    logo: "/img/partners/moneychamp.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Poppins', sans-serif",
    colors: {
      primary: "#25799d9e",
      secondary: "#004a80",
    },
    tancApply: false,
  },
  iwebtechno: {
    logo: "/img/partners/iweb.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap",
    fontFamily: "'Open Sans', sans-serif",
    colors: {
      primary: "#035fac",
      secondary: "#004a80",
    },
    title: "Need Money? Get Instant Credit With Your ID Card!*",
    tancApply: true,
  },
  toshanipremium: {
    logo: "/img/partners/toshanipremium.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap",
    fontFamily: "'Lato', sans-serif",
    colors: {
      primary: "#E10000",
      secondary: "#004a80",
    },
    tancApply: false,
  },
  faydapay: {
    logo: "/img/partners/faydapay.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap",
    fontFamily: "'Lato', sans-serif",
    colors: {
      primary: "#007bff",
      secondary: "#004a80",
    },
    tancApply: false,
  },
  vakrangeegold: {
    logo: "/img/partners/vakrangee.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap",
    fontFamily: "'Lato', sans-serif",
    colors: {
      primary: "#ED7725",
      secondary: "#000000",
    },
    tancApply: false,
  },
  askcredit: {
    logo: "/img/partners/askcredit.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap",
    fontFamily: "'Lato', sans-serif",
    colors: {
      primary: "#3A5CBA",
      secondary: "#000000",
    },
    tancApply: false,
  },
  lidservices: {
    logo: "/img/partners/lid.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Jost', sans-serif",
    colors: {
      primary: "#006633",
      secondary: "#212529",
    },
    tancApply: false,
  },
  paymonk: {
    logo: false,
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap",
    fontFamily: "'Montserrat', sans-serif",
    colors: {
      primary: "#1D388D",
      secondary: "#000000B0",
    },
    tancApply: false,
  },
  "jeevan-utthan": {
    logo: "/img/partners/jeevan-utthan.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap",
    fontFamily: "'Roboto', sans-serif",
    colors: {
      primary: "#8165F8",
      secondary: "#000000",
    },
    tancApply: false,
  },
  inforts: {
    logo: "/img/partners/inforts.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Rubik', sans-serif",
    colors: {
      primary: "#08B7C1",
      secondary: "#505050",
    },
    tancApply: false,
  },
  "1touchpe": {
    logo: "/img/partners/1touchpe.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap",
    fontFamily: "'Open Sans', sans-serif",
    colors: {
      primary: "#1D92D0",
      secondary: "#4E4E4E",
    },
    tancApply: false,
  },
  ezeepay: {
    logo: "/img/partners/ezeepay.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Poppins', sans-serif",
    colors: {
      primary: "#FD6101",
      secondary: "#303289",
    },
    tancApply: false,
  },
  dirsti: {
    logo: "/img/partners/dirsti.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#276FCF",
      secondary: "#070707",
    },
    tancApply: false,
  },
  visitmyloan: {
    logo: "/img/partners/visitmyloan.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#147F65",
      secondary: "#070707",
    },
    tancApply: false,
  },
  winoraa: {
    logo: "/img/partners/winoraa-wl.jpg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#DB4285",
      secondary: "#1D3885",
    },
    tancApply: false,
  },
  ygocompany: {
    logo: "/img/partners/ygocompany.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#276FCF",
      secondary: "#070707",
    },
    tancApply: false,
  },
  nitya_finance: {
    logo: "/img/partners/nitya_finance.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#276FCF",
      secondary: "#070707",
    },
    tancApply: false,
  },
  daypfinance: {
    logo: "/img/partners/daypfinance.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#276FCF",
      secondary: "#070707",
    },
    tancApply: false,
  },
  aadarsh_finance: {
    logo: "/img/partners/aadarsh_finance.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#276FCF",
      secondary: "#070707",
    },
    tancApply: false,
  },
  realzone: {
    logo: "/img/partners/realzone.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#276FCF",
      secondary: "#070707",
    },
    tancApply: false,
  },
  moneyflow: {
    logo: "/img/partners/moneyflow.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Inter', sans-serif",
    colors: {
      primary: "#276FCF",
      secondary: "#070707",
    },
    tancApply: false,
  },
  webpe: {
    logo: "/img/partners/webpe.jpeg",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap",
    fontFamily: "'Raleway', sans-serif;",
    colors: {
      primary: "#EE0979",
      secondary: "#212529",
    },
    tancApply: false,
  },
  viren: {
    logo: "/img/partners/viren.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap",
    fontFamily: "'Raleway', sans-serif;",
    colors: {
      primary: "#265e8b",
      secondary: "#898989",
    },
    tancApply: false,
  },
  "moral-e-suvidha": {
    logo: "/img/partners/moral-e-suvidha.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Karla', sans-serif;",
    colors: {
      primary: "#3F51B5",
      secondary: "#070050",
    },
    tancApply: false,
  },
  yoke: {
    logo: "/img/partners/yoke.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap",
    fontFamily: "'Montserrat', sans-serif",
    colors: {
      primary: "#FE5D14",
      secondary: "#020053",
    },
    tancApply: false,
  },
  indezon: {
    logo: "/img/partners/indezon.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap",
    fontFamily: "'Rubik', sans-serif",
    colors: {
      primary: "#1160D8",
      secondary: "#27282C",
    },
    tancApply: false,
  },
  dhanvriddhiassurance: {
    logo: "/img/partners/dhanvriddhiassurance.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap",
    fontFamily: "'Rubik', sans-serif",
    colors: {
      primary: "#124265",
      secondary: "#444444",
    },
    tancApply: false,
  },
  fivekcr: {
    logo: "/img/partners/fivekcr.png",
    googleFontsAPI:
      "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap",
    fontFamily: "'Poppins', sans-serif",
    colors: {
      primary: "#414195",
      secondary: "#141415",
    },
    tancApply: false,
  },
};

export default partners;
