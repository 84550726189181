const routes = [
  {
    path: "/admin/dashboard",
    role: [1, 2],
  },
  {
    path: "/admin/mis-upload",
    role: [1],
  },
  {
    path: "/admin/vendors-on-off",
    role: [1],
  },
  {
    path: "/admin/lenders",
    role: [1],
  },
  {
    path: "/admin/customer-eligibility",
    role: [1],
  },
  {
    path: "/admin/csr-portal",
    role: [1, 2, 3],
  },
  {
    path: "/admin/internal-report",
    role: [1, 2],
  },
  {
    path: "/admin/customer-info-deletion",
    role: [1],
  },
  {
    path: "/admin/customers-journey",
    role: [1, 2],
  },
  {
    path: "/admin/user-management",
    role: [1],
  },
  {
    path: "/admin/commission-upload-file",
    role: [1],
  },
  {
    path: "/admin/application-status-file",
    role: [1, 2],
  },
  {
    path: "/admin/merchant-management",
    role: [1],
  },
  {
    path: "/admin/tracking-links",
    role: [1],
  },
  {
    path: "/admin/credit-cards",
    role: [1],
  },
  {
    path: "/admin/partners",
    role: [1],
  },
  {
    path: "/admin/messaging",
    role: [1],
  },
  {
    path: "/admin/telecalling",
    role: [1, 3],
  },
];

export const hasAccessPermission = (path, roleId) => {
  const matchRoutes = routes.find((route) => {
    return path.startsWith(route.path);
  });
  if (matchRoutes) {
    return matchRoutes.role.includes(roleId);
  }
  return false;
};
