import { LoadingButton } from '@mui/lab'
import { Box, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

export default function SpecialOffersModal({ values, handleSave, onClose, open, loading }) {
    const [field, setField] = useState({
        offerText: "",
        startDate: "",
        endDate: "",
    })

    useEffect(() => {
        setField(values)
    }, [values])

    const handlValueChange = (e) => {
        setField({
            ...field,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal open={open} onClose={onClose} sx={{ zIndex: 100000 }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" mb={2} color="primary">Special Offer</Typography>
                <TextField
                    fullWidth
                    name='offerText'
                    label="Special Offer Text"
                    variant="outlined"
                    value={field.offerText}
                    onChange={handlValueChange}
                    //   margin="normal"
                    sx={{ mb: 4 }}

                />
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 4 }}>
                    <TextField
                        label="Date Start"
                        type="date"
                        name="startDate"
                        InputLabelProps={{ shrink: true }}
                        value={field.startDate}
                        onChange={handlValueChange}
                        sx={{ flex: 1, minWidth: 150 }}
                    />
                    <TextField
                        label="Date End"
                        type="date"
                        name="endDate"
                        InputLabelProps={{ shrink: true }}
                        value={field.endDate}
                        onChange={handlValueChange}
                        sx={{ flex: 1, minWidth: 150 }}
                    />
                </Box>
                <LoadingButton loading={loading} onClick={() => handleSave(field)} variant="contained" fullWidth>
                    Update
                </LoadingButton>
            </Box>
        </Modal>
    )
}
