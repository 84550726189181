import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  communicationPreference: [],
  employmentStatus: [],
  salaryMode: [],
  householdIncome: [],
  
  mvGender: [],
  mvResidencyType: [],
  mvEducationLevel: [],
  mvPreferNetBanking: [],

  upwrdGender: [],
  upwrdCurrentResidencyType: [],
  upwrdCurrentResidenceStayCategory: [],
  upwrdProfessionType: [],
  upwrdCurrentEmploymentTenure: [],
  upwrdTotalWorkExperience: [],
  upwrdLoanPurpose: [],

  mtGender: [],
  mtMaritalStatus: [],
  mtCompanyType: [],
  mtResidencyType: [],

  niraGender: [],
  niraMaritalStatus: [],
  niraJobSector: [],
  niraWorkStatus: [],

  casheGender: [],
  businessRegistrationType: [],
  ticketClass: [],
  creditScoreClass: [],
};

export const smartListSlice = createSlice({
  name: "smartList",
  initialState,
  reducers: {
    updateSmartList: (state, action) => {
      state.communicationPreference =
        action.payload.communicationPreference || [];
      state.employmentStatus = action.payload.employmentStatus || [];
      state.salaryMode = action.payload.salaryMode || [];
      state.householdIncome = action.payload.householdIncome || [];

      state.mvGender = action.payload.mvGender || [];
      state.mvResidencyType = action.payload.mvResidencyType || [];
      state.mvEducationLevel = action.payload.mvEducationLevel || [];
      state.mvPreferNetBanking = action.payload.mvPreferNetBanking || [];

      state.upwrdGender = action.payload.upwrdGender || [];
      state.upwrdCurrentResidencyType =
        action.payload.upwrdCurrentResidencyType || [];
      state.upwrdCurrentResidenceStayCategory =
        action.payload.upwrdCurrentResidenceStayCategory || [];
      state.upwrdProfessionType = action.payload.upwrdProfessionType || [];
      state.upwrdCurrentEmploymentTenure =
        action.payload.upwrdCurrentEmploymentTenure || [];
      state.upwrdTotalWorkExperience =
        action.payload.upwrdTotalWorkExperience || [];
      state.upwrdLoanPurpose = action.payload.upwrdLoanPurpose || [];

      state.mtGender = action.payload.mtGender || [];
      state.mtMaritalStatus = action.payload.mtMaritalStatus || [];
      state.mtCompanyType = action.payload.mtCompanyType || [];
      state.mtResidencyType = action.payload.mtResidencyType || [];

      state.niraGender = action.payload.niraGender || [];
      state.niraMaritalStatus = action.payload.niraMaritalStatus || [];
      state.niraJobSector = action.payload.niraJobSector || [];
      state.niraWorkStatus = action.payload.niraWorkStatus || [];

      state.casheGender = action.payload.casheGender || [];
      
      state.businessCurrentTurnover = action.payload.businessCurrentTurnover || [];
      state.businessYears = action.payload.businessYears || [];
      state.businessCompanyType = action.payload.businessCompanyType || [];
      state.businessNature = action.payload.businessNature || [];
      state.businessGrossProfit = action.payload.businessGrossProfit || [];
      state.businessRegistrationType = action.payload.businessRegistrationType || [];

      state.professionalType = action.payload.professionalType || [];
      state.professionalYears = action.payload.professionalYears || [];

      state.ticketClass = action.payload.ticketClass || [];
      state.creditScoreClass = action.payload.creditScoreClass || [];
    },
  },
});

// actions export
export const { updateSmartList } = smartListSlice.actions;

// initial state export
export { initialState };

export default smartListSlice.reducer;
