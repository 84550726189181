import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import { initialState } from "../../utils/reduxSlice/smartListSlice";

const smartListApi = async (partnerId) => {
  const dataObj = { ...initialState };

  try {
    let apiLink = null;
    if (partnerId !== null) {
      apiLink = `${endpoint}/api/search-list/en/${partnerId}`;
    } else {
      apiLink = `${endpoint}/api/search-list/en`;
    }
    const response = await axios.get(apiLink);
    const data = response.data;
    
    if (data) {
      // Communication preference
      if (data.commPreferences) {
        dataObj.communicationPreference = Object.values(data.commPreferences);
      }

      // Employment Status
      if (data.employmentStatus) {
        dataObj.employmentStatus = Object.values(data.employmentStatus);
      }

      // Salary mode
      if (data.salaryMode) {
        dataObj.salaryMode = Object.values(data.salaryMode);
      }

      // mvGender
      if (data.mvGender) {
        dataObj.mvGender = Object.values(data.mvGender);
      }

      // mvResidencyType
      if (data.residentType) {
        dataObj.mvResidencyType = Object.values(data.residentType);
      }

      // mvEducationLevel
      if (data.educationalLevel) {
        dataObj.mvEducationLevel = Object.values(data.educationalLevel);
      }

      // mvPreferNetBanking
      if (data.preferNetBanking) {
        dataObj.mvPreferNetBanking = Object.values(data.preferNetBanking);
      }

      // upwrdGender
      if (data.Gender) {
        dataObj.upwrdGender = Object.values(data.Gender);
      }

      // upwrdCurrentResidencyType
      if (data.currentResidencyType) {
        dataObj.upwrdCurrentResidencyType = Object.values(
          data.currentResidencyType
        );
      }

      // upwrdCurrentResidenceStayCategory
      if (data.upwardsResidentStayCategory) {
        dataObj.upwrdCurrentResidenceStayCategory = Object.values(
          data.upwardsResidentStayCategory
        );
      }

      // upwrdProfessionType
      if (data.professionType) {
        dataObj.upwrdProfessionType = Object.values(data.professionType);
      }

      // upwrdCurrentEmploymentTenure
      if (data.employeementTenure) {
        dataObj.upwrdCurrentEmploymentTenure = Object.values(
          data.employeementTenure
        );
      }

      // upwrdTotalWorkExperience
      if (data.totalWorkExperience) {
        dataObj.upwrdTotalWorkExperience = Object.values(
          data.totalWorkExperience
        );
      }

      // upwrdLoanPurpose
      if (data.loanPurpose) {
        dataObj.upwrdLoanPurpose = Object.values(data.loanPurpose);
      }

      // mtGender
      if (data.mtGender) {
        dataObj.mtGender = Object.values(data.mtGender);
      }

      // mtMaritalStatus
      if (data.mtMaritalStatus) {
        dataObj.mtMaritalStatus = Object.values(data.mtMaritalStatus);
      }

      // mtCompanyType
      if (data.mtCompanyType) {
        dataObj.mtCompanyType = Object.values(data.mtCompanyType);
      }

      // mtResidencyType
      if (data.mtResidencyType) {
        dataObj.mtResidencyType = Object.values(data.mtResidencyType);
      }

      // niraGender
      if (data.niraGender) {
        dataObj.niraGender = Object.values(data.niraGender);
      }

      // niraMaritalStatus
      if (data.niraMaritalStatus) {
        dataObj.niraMaritalStatus = Object.values(data.niraMaritalStatus);
      }

      // niraJobSector
      if (data.niraJobSector) {
        dataObj.niraJobSector = Object.values(data.niraJobSector);
      }

      // niraWorkStatus
      if (data.niraWorkStatus) {
        dataObj.niraWorkStatus = Object.values(data.niraWorkStatus);
      }

      // casheGender
      if (data.casheGender) {
        dataObj.casheGender = Object.values(data.casheGender);
      }

      // businessCurrentTurnover
      if (data.businessCurrentTurnover) {
        dataObj.businessCurrentTurnover = Object.values(data.businessCurrentTurnover);
      }

      // businessYears
      if (data.businessYears) {
        dataObj.businessYears = Object.values(data.businessYears);
      }

      // businessCompanyType
      if (data.businessCompanyType) {
        dataObj.businessCompanyType = Object.values(data.businessCompanyType);
      }

      // businessNature
      if (data.businessNature) {
        dataObj.businessNature = Object.values(data.businessNature);
      }

      // businessGrossProfit
      if (data.businessGrossProfit) {
        dataObj.businessGrossProfit = Object.values(data.businessGrossProfit);
      }

      // businessRegistrationType
      if(data.businessRegistrationType) {
        dataObj.businessRegistrationType = Object.values(data.businessRegistrationType);
      }

      // professionalType
      if (data.professionalType) {
        dataObj.professionalType = Object.values(data.professionalType);
      }

      // professionalYears
      if (data.professionalYears) {
        dataObj.professionalYears = Object.values(data.professionalYears);
      }

      // ticketClass
      if (data.ticketClass) {
        dataObj.ticketClass = Object.values(data.ticketClass);
      }

      // creditScoreClass
      if (data.creditScoreClass) {
        dataObj.creditScoreClass = Object.values(data.creditScoreClass);
      }

    }

    return dataObj;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export default smartListApi;
