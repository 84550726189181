import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Fade,
	Box,
	Modal,
	Backdrop,
	TextField,
	Dialog,
	useMediaQuery,
	Slide,
	DialogContent,
	DialogActions,
	Grid,
	IconButton,
	Checkbox,
	TableSortLabel,
	Select, MenuItem
} from '@mui/material';
import axios from 'axios';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { app_title, endpoint } from '../../config';
import styled from '@emotion/styled';
import { customToast } from '../../lib/toastLib';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from 'react-helmet-async';
import CreditCardUpdateDialog from '../../components/admin/CreditCardUpdateDialog';
import { EditContent } from './CreditCards';
import { useSelector } from "react-redux";
import SpecialOffersModal from './SpecialOffersModal';

const stickyStyle = {
	position: 'sticky',
	left: 0,
	background: '#fff', // Set background to avoid overlap issues
	zIndex: 999,
	borderRight: '1px solid rgba(224, 224, 224, 1)'// Ensure the sticky column is above other columns
};

const tableHeaders = [
	{ label: "Name", key: "name", sortable: false },
	{ label: "Revenue Share (%)", key: "revenue_percent", sortable: false },
	{ label: "Revenue Share Flat Fee (₹)", key: "revenue_flat_fee", sortable: false },
	{ label: "Income (min)", key: "min_income", sortable: false },
	{ label: "Income (max)", key: "max_income", sortable: false },
	{ label: "Age (min)", key: "min_age", sortable: false },
	{ label: "Age (max)", key: "max_age", sortable: false },
	{ label: "Ticket Class", key: "ticket_class", sortable: false },
	{ label: "Credit Score Class", key: "credit_score_class", sortable: false },
	{ label: "Salaried", key: "salaried", sortable: false },
	{ label: "Self Employed", key: "self_employed", sortable: false },
	{ label: "Loan Amount upto", key: "loan_amount_upto", sortable: false },
	{ label: "Tenure (months)", key: "tenure", sortable: false },
	{ label: "Interest Rate", key: "interest_rate", sortable: false },
	{ label: "Monthly PR (min)", key: "apr_min", sortable: false },
	{ label: "Monthly PR (max)", key: "apr_max", sortable: false },
	{ label: "Processing Fees (% of loan amount)", key: "processing_fees", sortable: false },
	{ label: "Business", key: "business", sortable: false },
	{ label: "Priority", key: "priority", sortable: false },
	{ label: "Lender Status", key: "lender_status", sortable: false },
	{ label: "Special Offer", key: "special_offer", sortable: false },
];

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function Lenders() {
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [editName, setEditName] = useState(null);
	const [editRecord, setEditRecord] = useState({});

	// Fetch lender list
	const [lenders, setLenders] = useState([]);
	useEffect(() => {
		fetchLendersList()
	}, [])

	const ticketClass = useSelector((state) => state.smartList.ticketClass);
	const creditScoreClass = useSelector((state) => state.smartList.creditScoreClass);

	const fetchLendersList = () => {
		axios.get(`${endpoint}/api/get-lenders-data`)
			.then(({ data }) => {
				setLenders(data)
			})
			.catch((error) => {
				if (error.response) {
					customToast.error(error.response.data?.message);
				} else {
					customToast.error(error.message);
				}
			})
	}

	// Create Lender
	const [showLenderModal, setShowLenderModal] = useState(false);
	const [lenderName, setLenderName] = useState("")
	const [createLenderLoading, setCreateLenderLoading] = useState(false)

	const handleInputChange = (e, index) => {
		const name = e.target.name;
		let value = e.target.value;

		if (["salaried", "self_employed", "business", "lender_status", "so_status"].includes(name)) {
			value = e.target.checked ? 1 : 0;
		}

		if (name === 'priority') {
			if (isNaN(Number(value)) || Number(value) < 0) return;
		}

		const updateLenders = lenders.map((lender, i) => {
			if (i === index) {
				return {
					...lender,
					[name]: value
				}
			}
			return lender;
		});

		setLenders(updateLenders);
	}

	const createLender = (e) => {
		e.preventDefault();

		setCreateLenderLoading(true)
		axios.post(`${endpoint}/api/create-lender`, {
			name: lenderName
		}).then(() => {
			setLenderName("")
			fetchLendersList()
			setShowLenderModal(false)
			customToast.success("Lender created successfully!");
		}).catch((error) => {
			if (error.response) {
				customToast.error(error.response.data?.message);
			} else {
				customToast.error(error.message);
			}
		}).finally(() => {
			setCreateLenderLoading(false)
		})
	}

	// Update the Lender field
	const [editedIndex, setEditedIndex] = useState({
		row: -1,
		column: ""
	});

	const updateTheField = (e, id) => {
		const name = e.target.name;
		let value = e.target.value;

		if (["salaried", "self_employed", "business", "lender_status", "so_status"].includes(name)) {
			value = e.target.checked ? 1 : 0;
		}

		if (["apr_min", "apr_max"].includes(name)) {
			value = parseFloat(value);
		}

		axios.post(`${endpoint}/api/update-lender-data`, {
			lender_id: id,
			property: name,
			value: value
		}).then(() => {
			customToast.success("Lender updated successfully!");
		}).catch((error) => {
			if (error.response) {
				customToast.error(error.response.data?.message);
			} else {
				customToast.error(error.message);
			}
		}).finally(() => {
			setEditedIndex({
				row: -1,
				column: ''
			})
		})
	}

	// Delete Lender
	const [deleteId, setDeleteId] = useState(-1);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const deleteLender = () => {
		setDeleteLoading(true)
		axios.post(`${endpoint}/api/safe-delete-lender`, {
			lender_id: deleteId
		}).then(() => {
			customToast.success("Lender deleted successfully!");
			setLenders((prev) => {
				return prev.filter(item => item.lender_id !== deleteId)
			})
			setDeleteId(-1);
		}).catch((error) => {
			if (error.response) {
				customToast.error(error.response.data?.message);
			} else {
				customToast.error(error.message);
			}
		}).finally(() => {
			setDeleteLoading(false)
		})
	}

	const handleEdit = (name, record) => {
		setEditName(name)
		setEditRecord({
			id: record.lender_id,
			...record
		})
	}

	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("name");

	const handleSort = (key) => {
		const isAsc = orderBy === key && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(key);
	};

	// const sortedRows = [...rows].sort((a, b) => {
	// 	if (!orderBy) return 0;
	// 	const aValue = a[orderBy] || "";
	// 	const bValue = b[orderBy] || "";
	// 	return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
	// })

	const [specialOffer, setSpecialOffer] = useState({
		offerText: "",
		startDate: "",
		endDate: ""
	})

	const handleSpecialOfferChange = (e) => {
		const { name, value } = e.target;
		setSpecialOffer((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const [open, setOpen] = useState(false);

	const [selectedLender, setSelectedLender] = useState({})
	const [selectedLenderId, setSelectedLenderId] = useState(null)
	const [specialOfferLoading, setSpecialOfferLoading] = useState(false)

	const updateSpecialOffer = async (field) => {
		setSpecialOfferLoading(true)
		const fieldsToUpdate = [
			{ property: "so_text", value: field.offerText },
			{ property: "so_date_start", value: field.startDate },
			{ property: "so_date_end", value: field.endDate },
		];

		const updatePromises = fieldsToUpdate.map(field =>
			axios.post(`${endpoint}/api/update-lender-data`, {
				lender_id: selectedLenderId,
				property: field.property,
				value: field.value
			})
		);

		try {
			await Promise.all(updatePromises);
			setLenders((prev) => {
				return prev.map((lender) => {
					if (lender.lender_id === selectedLenderId) {
						return { ...lender, so_text: field.offerText, so_date_start: field.startDate, so_date_end: field.endDate };
					}
					return lender;
				})
			})
			customToast.success("Lender updated successfully!");
			setSelectedLender(null)
			setSelectedLenderId(null)
			setOpen(false)
		} catch (error) {
			if (error.response) {
				customToast.error(error.response.data?.message);
			} else {
				customToast.error(error.message);
			}
		} finally {
			setSpecialOfferLoading(false)
		}
	};

	return <>
		<Helmet>
			<title>Admin - Lenders {app_title && `| ${app_title}`}</title>
		</Helmet>
		<Paper variant="outlined" sx={{ padding: 2 }}>
			<Box sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center'
			}}>
				<Typography variant="h6" color="secondary.main" mb={2}>Lenders</Typography>
				<Button
					variant='contained'
					onClick={() => {
						setShowLenderModal(true)
						setLenderName("")
					}}
				>
					Add Lender
				</Button>
			</Box>

			{/* begin::Table Container */}
			<TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow>
							{tableHeaders.map((header) => (
								<TableCell key={header.key}
									sx={{
										...stickyStyle,
										zIndex: 99999,
										whiteSpace: 'nowrap'
									}}
								>
									{header.sortable ? (
										<TableSortLabel
											active={orderBy === header.key}
											direction={orderBy === header.key ? order : "asc"}
											onClick={() => handleSort(header.key)}
										>
											{header.label}
										</TableSortLabel>
									) : (
										header.label
									)}
								</TableCell>
							))}
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{lenders.map((lender, idx) => (
							<TableRow key={idx}>
								<TableCell sx={stickyStyle}>
									{lender.name}
								</TableCell>
								<TableCell align="center" component="th" scope="row" sx={{
									position: 'relative',
									maxWidth: '200px',
									'&:hover .hover-box': {
										display: 'flex',
									},
								}}>
									{lender.revenue_percent.toFixed(2) ?? 0}%
									<EditContent onClick={() => handleEdit('revenue_percent', lender)} />
								</TableCell>
								<TableCell align="center" component="th" scope="row" sx={{
									position: 'relative',
									maxWidth: '200px',
									'&:hover .hover-box': {
										display: 'flex',
									},
								}}>
									{lender.revenue_flat_fee ?? 0}
									<EditContent onClick={() => handleEdit('revenue_flat_fee', lender)} />
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'min_income' ?
										<TextField value={lender.min_income} name="min_income" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'min_income'
											})
										}}>
											{lender.min_income}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'max_income' ?
										<TextField value={lender.max_income} name="max_income" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'max_income'
											})
										}}>
											{lender.max_income}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'min_age' ?
										<TextField value={lender.min_age} name="min_age" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'min_age'
											})
										}}>
											{lender.min_age}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'max_age' ?
										<TextField value={lender.max_age} name="max_age" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'max_age'
											})
										}}>
											{lender.max_age}
										</Button>
									}
								</TableCell>
								<TableCell>
									<Select
										value={lender.ticket_class}
										name="ticket_class"
										onChange={(e) => {
											handleInputChange(e, idx);
											updateTheField(e, lender.lender_id);
										}}
										sx={{
											width: '100%'
										}}
									>
										{ticketClass && ticketClass.map((option, idx) => (
											<MenuItem key={option.id || idx} value={option.id}>
												{option.value}
											</MenuItem>
										))}
									</Select>
								</TableCell>
								<TableCell>
									<Select
										value={lender.credit_score_class}
										name="credit_score_class"
										onChange={(e) => {
											handleInputChange(e, idx);
											updateTheField(e, lender.lender_id);
										}}
										sx={{
											width: '100%'
										}}
									>
										{creditScoreClass && creditScoreClass.map((option, idx) => (
											<MenuItem key={option.id || idx} value={option.id}>
												{option.value}
											</MenuItem>
										))}
									</Select>
								</TableCell>
								<TableCell>
									{/* <TextField value={lender.salaried} name="salaried" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} /> */}
									<Checkbox
										name="salaried"
										checked={lender.salaried === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id)
										}}
									/>
								</TableCell>
								<TableCell>
									<Checkbox
										name="self_employed"
										checked={lender.self_employed === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id)
										}}
									/>
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'loan_amount_upto' ?
										<TextField value={lender.loan_amount_upto} name="loan_amount_upto" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'loan_amount_upto'
											})
										}}>
											{lender.loan_amount_upto}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'tenure' ?
										<TextField value={lender.tenure} name="tenure" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />

										:
										<Button style={{ color: 'unset', textTransform: "capitalize" }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'tenure'
											})
										}}>
											{lender.tenure}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'interest_rate' ?
										<TextField value={lender.interest_rate} name="interest_rate" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset', textTransform: 'capitalize' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'interest_rate'
											})
										}}>
											{lender.interest_rate}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'apr_min' ?
										<TextField value={lender.apr_min} name="apr_min" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'apr_min'
											})
										}}>
											{lender.apr_min}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'apr_max' ?
										<TextField value={lender.apr_max} name="apr_max" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'apr_max'
											})
										}}>
											{lender.apr_max}
										</Button>
									}
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'processing_fees' ?
										<TextField value={lender.processing_fees} name="processing_fees" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'processing_fees'
											})
										}}>
											{lender.processing_fees}
										</Button>
									}
								</TableCell>
								<TableCell>
									<Checkbox
										name="business"
										checked={lender.business === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id)
										}}
									/>
								</TableCell>
								<TableCell>
									{editedIndex.row === idx && editedIndex.column === 'priority' ?
										<TextField value={lender.priority} name="priority" onBlur={(e) => updateTheField(e, lender.lender_id)} onChange={(e) => handleInputChange(e, idx)} />
										:
										<Button style={{ color: 'unset' }} title='Click to edit' onClick={() => {
											setEditedIndex({
												row: idx,
												column: 'priority'
											})
										}}>
											{lender.priority}
										</Button>
									}
								</TableCell>
								<TableCell>
									<Checkbox
										name="lender_status"
										checked={lender.lender_status === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id)
										}}
									/>
								</TableCell>
								<TableCell sx={{ minWidth: 160 }}>
									<Checkbox
										name="so_status"
										checked={lender.so_status === 1}
										onChange={(e) => {
											handleInputChange(e, idx)
											updateTheField(e, lender.lender_id);
										}}
									/>
									<Button v onClick={() => {
										setSelectedLender(lender);
										setSelectedLenderId(lender.lender_id);
										setOpen(true)
									}}>
										Edit
									</Button>
								</TableCell>
								<TableCell>
									<Button
										color="error"
										variant="contained"
										size="small"
										onClick={() => setDeleteId(lender.lender_id)}
									>
										Delete
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{/* end::Table Container */}

			{/* begin::Delete confirmation popup */}
			<StyledModal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={deleteId !== -1}
				onClose={() => setDeleteId(-1)}
				closeAfterTransition
				slots={{ backdrop: StyledBackdrop }}
			>
				<Fade in={deleteId !== -1}>
					<Box sx={style}>
						<Typography align='center' variant="h6" color="secondary.main" mb={2}>Are you sure?</Typography>
						<Box sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: 2,
						}}>
							<Button
								color="primary"
								variant="outlined"
								size="small"
								onClick={() => setDeleteId(-1)}
							>
								Cancel
							</Button>
							<LoadingButton
								loading={deleteLoading}
								color="error"
								variant="contained"
								size="small"
								onClick={deleteLender}
							>
								Delete
							</LoadingButton>
						</Box>
					</Box>
				</Fade>
			</StyledModal>
			{/* end::Delete confirmation popup */}

			{/* begin::Create lender modal */}
			<Dialog
				open={showLenderModal}
				scroll="paper"
				fullScreen={fullScreen}
				fullWidth
				TransitionComponent={Transition}
				component="form"
				autoComplete="off"
				onSubmit={createLender}
			>
				<DialogContent>
					<Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
						<IconButton onClick={() => setShowLenderModal(false)}>
							<CloseIcon />
						</IconButton>
					</Grid>
					<Grid container justifyContent="center" spacing={3}>
						<Grid item xs={12}>
							<TextField
								margin="dense"
								required
								fullWidth
								label={t("Name")}
								name="lenderName"
								value={lenderName}
								onChange={(e) => setLenderName(e.target.value)}
							>
							</TextField>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						loading={createLenderLoading}
						color="secondary"
						variant="contained"
						size="large"
						fullWidth
						type="submit"
					>
						{t("Submit")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
			{/* end::Create lender modal */}
		</Paper>

		<CreditCardUpdateDialog
			open={!!editName}
			name={editName}
			type="number"
			record={editRecord}
			onClose={() => {
				setEditRecord({})
				setEditName(null)
			}}
			onSave={() => {
				fetchLendersList()
				setEditRecord({})
				setEditName(null)
			}}
			module={"lenders"}
		/>

		<SpecialOffersModal
			open={open}
			loading={specialOfferLoading}
			values={{
				offerText: selectedLender?.so_text || "",
				startDate: selectedLender?.so_date_start || "",
				endDate: selectedLender?.so_date_end || "",
			}}
			handleSave={updateSpecialOffer}
			onClose={() => {
				setSelectedLenderId(null)
				setSelectedLender(null)
				setOpen(false)
			}}
		/>

	</>
}

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	borderRadius: '12px',
	padding: '16px 32px 24px 32px',
	backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
	boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});